/* @font-face {
  font-family: "Segoe UI";
  font-weight: 200;
  src: url("/Fonts/Segoe_UI.ttf");
}

@font-face {
  font-family: "Segoe UI";
  font-weight: 700;
  src: url("/Fonts/Segoe_UI_Bold.ttf");
}

@font-face {
  font-family: "Segoe UI";
  font-style: italic;
  font-weight: 400;
  src: url("/Fonts/Segoe_UI_Italic.ttf");
}

@font-face {
  font-family: "Segoe UI";
  font-style: italic;
  font-weight: 700;
  src: url("/Fonts/Segoe_UI_Bold_Italic.ttf");
} */

body {
  font-family: "Segoe UI", sans-serif !important;
  overflow-x: hidden;
  margin: 0px;
}
/*******Navbar Header styling *******/

.navbar a {
  text-decoration: none;
}

.header-navbar {
  background-color: #a6caec;
  padding: 25px !important;
  display: flex;
  justify-content: space-around !important;
  align-items: center;
}

.header-navbar .navbar-brand img {
  width: 30%;
  margin-right: 10px;
}

.header-navbar .navbar-brand span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.sharesec {
  display: flex;
  gap: 30px;
}

.sharesec i {
  font-size: 20px;
  color: #fff;
}

.sharesec span {
  font-weight: 600;
}

.orange-bar {
  background-color: #e38a15;
}

.pagelink {
  color: #fff !important;
  font-size: 15px !important;
}

.orangenavsec {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.orangenavsec .nav-item {
  padding: 15px;
  cursor: pointer;
}

.twitter-svg svg {
  margin-top: 5px;
}

/******* Banner sec styling *********/

.banner {
  background-image: url(../public/assests/images/Background.jpg);
  background-size: cover;
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  color: white;
  text-align: center;
  overflow: hidden;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  font-size: 3.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/******* Petiton text sec styling *********/
.petitiontext {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.maintextsec .text1 {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: start;
  padding: 20px 0;
  text-align: center;
}

.text2 span {
  color: #a6caec;
  font-size: 1.9rem;
  font-weight: bold;
}

.text2 {
  color: #e38a15;
  font-size: 1.9rem;
  font-weight: bold;
}

.detailtext {
  text-align: justify;
  color: #000;
  font-size: 15px;
}

.readmoreBtn {
  width: 100%;
  background-color: #e38a15;
  border: none;
}

/******* Progress bar sec styling *********/
.progresscontainer {
  padding: 25px 0;
}

.progress-bar {
  background-color: #7a318a !important;
  /* width: 20% !important; */
}

.progresstext {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress {
  height: 8px !important;
}

.progresstext .sign {
  color: #7a318a;
  font-size: 20px;
}

.progresstext .goal {
  color: #707070;
  font-size: 20px;
}

.progresstext span {
  font-weight: bold;
}

.supportbtn {
  background-color: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
  border-radius: 20px !important;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #000 !important;
  gap: 6px;
}

.progressfieldsec {
  padding-left: 20px;
  padding-right: 20px;
}

.ourgoal {
  color: #e38a15;
  font-size: 17px;
  font-weight: 500;
}

/********** Form section Styling **********/
/* .formsection{
margin-left: 15px;
} */

.formsection .form-label {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}

/* .formsection input{
  width: 80%;
} */

.formsection h3 {
  margin-bottom: 15px;
  font-weight: 600;
}

/********* Radio section styling ***********/
.radiosection {
  margin-top: 40px;
}

.radiosection label {
  font-size: 14px;
  color: #000;
}

.form-check {
  margin-top: 10px;
}

/*Sign Petition btn*/

.signbtn {
  background-color: #ce3435 !important;
  border-color: #ce3435 !important;
  color: #fff;
  width: 100%;
  font-weight: 600;
}

.signbtn a {
  text-decoration: none;
  color: #fff;
}

/******Blue container section *****/
.bluecontainer{
  background-color: #fff;
  margin: 20px;
  padding: 15px;
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
    padding-right: 0px;

}

.bluepatchone{
  background-color: #a6caec;
  margin: 20px;
  padding: 15px;
  margin-right: 0px;
  margin-left: 0px;
}

.bluepatchtwo{
  background-color: #a6caec;
  margin: 20px;
  padding: 15px;
  margin-right: 0px;
  margin-left: 0px;
}

.bluepatchthree{
  background-color: #a6caec;
  margin: 20px;
  padding: 15px;
  margin-right: 0px;
  margin-left: 0px;
  height: 525px;
}

.bluepatchthree a{
  text-decoration: none !important;
}

.furtherread{
  font-size: 15px;
    color: #000;
}

.furtherread span{
font-weight: bold;
}

.furtherreadpoints{
  font-size: 15px;
}

.bluecontainer .whotext {
  color: #0e99d7;
  font-weight: 600;
}

.bluecontainer ul {
  color: #000;
}

.bluecontainer h5 {
  color: #e38a15;
  font-weight: 600;
}

.bluecontainer .description {
  text-align: justify;
  font-size: 14px;
  color: #000;
}

.bluecontainer .source {
  font-weight: 600;
  font-size: 18px;
}

.latestread{
  font-weight: bold;
}

.bluecontainer .italictext {
  font-style: italic;
  font-size: 14px;
}

.blueheader {
  background-color: #0e99d7;
  border-radius: 7px;
  padding: 2px;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  width: 100%;
}

.figures {
  font-size: 14px;
  color: #000;
  padding-left: 6px;
}

.figures .asoftext{
  font-size: 14px;
  color: #000;
}

.asoftext{
  font-size: 14px;
  color: #000;
  margin-left: 7px;
}

.figures span {
  font-size: 14px;
  font-weight: 800;
  color: #000;
}

.figuredetails {
  /* list-style: none; */
  font-size: 14px;
  font-weight: 800;
  color: #000;
  /* padding-left: 6px !important; */
}

.designbtn {
  background-color: #e38a15 !important;
  border-color: #e38a15 !important;
  border-radius: 10px !important;
  font-size: 15px;
  font-weight: 600;
}

.designsec {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.fa-hand-pointer {
  margin-top: 10px;
  margin-left: 10px;
  color: #e38a15;
  font-size: 70px;
}

.bluecontainer.first {
  height: 80%;
  overflow: hidden;
}

/********Action section styling ********/

.actionsec h2 {
  margin-top: 20px;
  color: #0e99d7;
  font-weight: 600;
}

.actionpara1 {
  margin-top: 20px;
  color: #000;
  font-size: 15px;
}

.actionpara1 span {
  font-weight: 600;
}

.readmore a {
  background-color: #e38a15;
  padding: 5px;
  color: #fff;
  text-align: center;
  border-color: #e38a15;
  width: 100%;
}

.readmore .btn:hover {
  background-color: #e38a15;
  border-color: #e38a15;
}

.readmore p {
  width: 100%;
}

.actionpara2 {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
}

.highlightedtxt {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  list-style-type: none;
  text-align: center;
}

/**********************************Part 2 styling **************************************/

/*****Further Reading section *****/

.readingtxt {
  margin-top: 20px;
  color: #0e99d7;
  font-weight: 600;
  margin-left: 12px;
}

.detailtext span {
  font-weight: 600;
}

.detailedlists {
  color: #000;
  text-align: justify;
  font-size: 15px;
  line-height: 1.9;
  padding-left: 25px;
}

.petitionrow.furthersec {
  margin-top: 30px;
}

.petitionrow.furthersec .detailtext {
  margin-left: 12px;
}

/************** footer section styling************/

footer .banner-text {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  font-size: 3.2rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

footer {
  margin-top: 25px;
}

/**********Sign petition page 3*************/

.formsection.signpetition h3 {
  color: #0e99d7;
}

.selecttext {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}

.workforsec .selecttext {
  margin-top: 10px;
}

.radiorow {
  display: flex;
}

.erroMsg {
  display: flex;
}

/* Mobile layout css */

@media screen and (min-width: 320px) and (max-width: 767px) {
  .banner {
    background-position: top;
    background-size: contain;
    height: 160px;
  }

  .banner-text {
    font-size: 2rem;
    left: 50%;
  }

  footer .banner-text {
    font-size: 1.2rem;
    top: 32%;
    left: 68%;
  }

  .orangenavsec {
    gap: 0px;
  }

  .radiosection .radiorow {
    flex-direction: column;
  }

  .detailedlists{
    padding-left: 10px;
  }

}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .bluecontainer.first {
    height: auto;
  }

  .petitiontext{
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.highlightedtxt{
    padding-left: 0px !important;
}

.actionsec.mobileview{
  display: block !important;
}

.actionsec{
  display: none;
}

.bluepatchthree{
  height: auto;
}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  footer .banner-text {
    font-size: 1.8rem;
    top: 38%;
    left: 55%;
  }

  .orangenavsec {
    gap: 0px;
  }
  .banner {
    background-size: contain;
    height: 280px;
  }

  .bluepatchthree{
    height: auto;
  }
}
